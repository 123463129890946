import React from 'react'
import styled from 'styled-components'
import SEO from "../components/seo"

const Wrap = styled.div`
	width: 100%;
	height: 100vh;
	& iframe {
		width: 100%;
		height: 100%;
		border: 0;
		border-width: 0px;
		margin-bottom: -10px !important;
	}
`
const ThreeDSteelBuildingCostEstimator = () => {
	return(
		<Wrap>
			<SEO title="3D Building Cost Estimator | Metal Structure Estimator | Price & Buy Online" description="Our 3D Building Cost Estimator allows you to customize and price your steel structure in real-time. Check it now and You can easily order your metal building in a few clicks. Buy Now."/>
			<iframe src="https://premiermetalstructures.sensei3d.com/" scrolling="no" frameborder="0"></iframe>
		</Wrap>
	)
}

export default ThreeDSteelBuildingCostEstimator
